import React from "react"
import StatusContent from "../../components/statusContent"

import "../../assets/styles/page/kycStatusPage.scss"
import { Link } from "gatsby"

const Status = () => {
  return (
    <div className="status">
      <div className="inner-wrapper">
        <div className="logo-wrapper">
          <div className="logo-button">
            <div className="logo-icon">
              <Link to="/" className="inner--logo--wrapper">
                <div className="svg--wrapper">
                  <svg
                    className="logo"
                    width="141"
                    height="31"
                    viewBox="0 0 141 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_13_46012)">
                      <path
                        d="M24.4726 9.397C24.481 11.2862 23.9135 13.1331 22.8458 14.6917C21.9987 15.9742 20.8367 17.0179 19.471 17.7229C18.565 18.2217 17.5811 18.5634 16.561 18.7334H16.4599H16.0456L14.4795 17.9048L8.89179 14.9038L3.53651 17.7533V14.8937L8.89179 12.0443L16.5508 16.0861L17.0359 15.9547C18.661 15.4857 20.0521 14.4261 20.9361 12.984C21.5669 11.9414 21.9098 10.7502 21.9301 9.53175C21.9504 8.31335 21.6472 7.11135 21.0515 6.04832C20.4558 4.98528 19.5889 4.09922 18.5391 3.48046C17.4893 2.8617 16.2942 2.53238 15.0756 2.52608H2.52608V9.83149L0 11.1754V0H15.0756C17.567 0.00267463 19.9556 0.993572 21.7173 2.75527C23.479 4.51697 24.4699 6.90558 24.4726 9.397Z"
                        fill="#B6A08F"
                      />
                      <path
                        d="M22.8559 16.3285C22.2882 16.9719 21.6395 17.5391 20.926 18.0159C21.5903 19.0941 21.9402 20.3365 21.9364 21.6029C21.9338 23.4244 21.209 25.1705 19.921 26.4584C18.6331 27.7464 16.887 28.4711 15.0655 28.4738H2.52608V13.8327L3.53651 13.2972L8.89179 10.4376L15.9042 14.1459L17.147 14.8532C18.041 14.52 18.8392 13.9716 19.471 13.2567L17.1773 11.9634L8.89179 7.57812L3.53651 10.4275L0 12.317V30.9999H15.0756C16.7831 31.0005 18.4584 30.5358 19.9217 29.6559C21.3849 28.7759 22.5807 27.5139 23.3807 26.0054C24.1806 24.4969 24.5545 22.799 24.462 21.0941C24.3696 19.3891 23.8143 17.7416 22.8559 16.3285Z"
                        fill="#2D3C49"
                      />
                      <path
                        d="M54.1595 31L47.0307 21.0401C46.2087 21.1273 45.3827 21.1715 44.556 21.1725H36.2255V31H32.9463V0H44.556C52.4384 0 57.2147 3.98193 57.2147 10.6321C57.317 12.8262 56.6658 14.9893 55.369 16.7622C54.0722 18.5351 52.208 19.811 50.0859 20.3781L57.7035 31H54.1595ZM53.9864 10.6321C53.9456 5.67247 50.6664 2.79041 44.4644 2.79041H36.2255V18.3821H44.4644C50.6664 18.3821 53.9456 15.5 53.9456 10.6321H53.9864Z"
                        fill="#2D3C49"
                      />
                      <path
                        d="M61.9199 15.48C61.9199 6.61 68.6999 0 77.9199 0C87.1399 0 93.9199 6.57 93.9199 15.48C93.9199 24.39 87.0499 31 77.9199 31C68.7899 31 61.9199 24.35 61.9199 15.48ZM90.6599 15.48C90.699 13.8023 90.3951 12.1343 89.767 10.5781C89.1389 9.02195 88.1998 7.61038 87.0071 6.42985C85.8144 5.24932 84.3932 4.32468 82.8307 3.71257C81.2682 3.10045 79.5971 2.81374 77.9199 2.87C70.5699 2.87 65.0899 8.22 65.0899 15.48C65.0899 22.74 70.5699 28.09 77.9199 28.09C79.5971 28.1463 81.2682 27.8595 82.8307 27.2474C84.3932 26.6353 85.8144 25.7107 87.0071 24.5302C88.1998 23.3496 89.1389 21.938 89.767 20.3819C90.3951 18.8257 90.699 17.1577 90.6599 15.48Z"
                        fill="#2D3C49"
                      />
                      <path
                        d="M98.3999 15.48C98.3999 6.61 105.18 0 114.4 0C123.62 0 130.4 6.57 130.4 15.48C130.4 24.39 123.53 31 114.4 31C105.27 31 98.3999 24.35 98.3999 15.48ZM127.14 15.48C127.179 13.8023 126.875 12.1343 126.247 10.5781C125.619 9.02195 124.68 7.61038 123.487 6.42985C122.294 5.24932 120.873 4.32468 119.311 3.71257C117.748 3.10045 116.077 2.81374 114.4 2.87C107.05 2.87 101.57 8.22 101.57 15.48C101.57 22.74 107.05 28.09 114.4 28.09C116.077 28.1463 117.748 27.8595 119.311 27.2474C120.873 26.6353 122.294 25.7107 123.487 24.5302C124.68 23.3496 125.619 21.938 126.247 20.3819C126.875 18.8257 127.179 17.1577 127.14 15.48Z"
                        fill="#2D3C49"
                      />
                      <path d="M137.551 0H140.82V31H137.551V0Z" fill="#2D3C49" />
                    </g>
                    <defs>
                      <clipPath id="clip0_13_46012">
                        <rect width="141" height="31" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="header-wrapper"></div>
        <div className="content-wrapper">
          <div className="inner-content-wrapper-one">
            <div className="inner-content-wrapper-two">
              <div className="inner-content-wrapper-three">
                <StatusContent />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Status

import { Link } from "gatsby"
import React from "react"
import { useSelector } from "react-redux"
import "../assets/styles/components/statusContent.scss"

const StatusContent = () => {
  const kycProfileStatus = useSelector((state: any) => state.profileKyc.kycProfileStatus)

  const userLevel = kycProfileStatus?.profile?.level
  const yourInfo = kycProfileStatus?.profile?.yourInfo
  const businessInfo = kycProfileStatus?.profile?.businessInfo
  const beneficialOwners = kycProfileStatus?.profile?.beneficialOwners
  const otherBeneficialOwners = beneficialOwners?.otherBeneficialOwners
  const accountManagers = kycProfileStatus?.profile?.accountManagers
  const hasAccountManager = accountManagers?.hasAccountManager



  let kycLevels = 4
  let path = "/your-identity"
  let baseLink = "/account-details/single-professional-host"

  if (kycProfileStatus?.profile?.hostType === "individual") {
    if (kycProfileStatus?.profile?.regType === "private") {
      kycLevels = 2
      baseLink = "/account-details/individual-host"
      userLevel === 1 ? (path = "/has-account-manager") : null
    } else {
      kycLevels = 4
      baseLink = "/account-details/single-professional-host"

      userLevel === 1
        ? (path = "/business-info")
        : userLevel === 2
        ? (path = "/is-beneficial-owner")
        : userLevel === 3
        ? (path = "/has-account-manager")
        : null
    }
  }

  if (kycProfileStatus?.profile?.hostType === "partnership") {
    if (kycProfileStatus?.profile?.regType === "private") {
      kycLevels = 2
      baseLink = "/account-details/private-partnership-host"
      userLevel === 1 ? (path = "/has-account-manager") : null
    } else {
      kycLevels = 4
      baseLink = "/account-details/professional-partnership-host"

      userLevel === 1
        ? (path = "/business-info")
        : userLevel === 2
        ? (path = "/is-beneficial-owner")
        : userLevel === 3
        ? (path = "/has-account-manager")
        : null
    }
  }

  if (kycProfileStatus?.profile?.hostType === "private company") {
    kycLevels = 4
    baseLink = "/account-details/private-company-host"

    userLevel === 1
      ? (path = "/business-info")
      : userLevel === 2
      ? (path = "/is-beneficial-owner")
      : userLevel === 3
      ? (path = "/has-account-manager")
      : null
  }

  if (kycProfileStatus?.profile?.hostType === "public company") {
    kycLevels = 3
    baseLink = "/account-details/public-company-host"
    userLevel === 1 ? (path = "/business-info") : userLevel === 2 ? (path = "/has-account-manager") : null
  }

  if (kycProfileStatus?.profile?.hostType === "government-owned company") {
    kycLevels = 3
    baseLink = "/account-details/government-company-host"
    userLevel === 1 ? (path = "/business-info") : userLevel === 2 ? (path = "/has-account-manager") : null
  }

  return (
    <div className="status-content">
      <div className="content-info">
        <div className="display-wrapper">
          <div className="display">
            <img
              className="display-img"
              aria-hidden="true"
              alt=""
              src="https://a0.muscache.com/pictures/88fe2e16-27d5-48b4-9d71-9a83f7e2c70e.jpg"
              data-original-uri="https://a0.muscache.com/pictures/88fe2e16-27d5-48b4-9d71-9a83f7e2c70e.jpg"
            />
          </div>
        </div>
        <div className="info">
          <div className="upper-text">
            <h1 className="heading">Verify your Host account</h1>
            <div className="text-body">
              <p>It might help to have these on hand:</p>
              <ul className="text-list">
                <li className="list-item">Contact info for people you work with</li>
                <li className="list-item">Business registration documents (if you have them)</li>
              </ul>
            </div>
          </div>
          <div className="learn-more">
            <button className="learn-btn">Learn more</button>
          </div>
          <div className="hosting-info">
            <div className="icon-wrapper">
              <div className="question-icon">
                <svg
                  viewBox="0 0 32 32"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-label="help"
                  role="img"
                  focusable="false"
                  style={{ display: "block", height: "16px", width: "16px", fill: "white" }}
                >
                  <path d="m16 1c-8.271 0-15 6.729-15 15s6.729 15 15 15 15-6.729 15-15-6.729-15-15-15zm0 25a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm1.506-8.062-.006 2.066-3-.008.011-4.288 1.102-.301c1.295-.353 3.466-1.266 3.466-3.407 0-1.833-1.528-2.668-2.948-2.668-1.423 0-2.65.947-3.054 2.358l-2.884-.826c.776-2.711 3.162-4.532 5.938-4.532 3.391 0 5.948 2.437 5.948 5.668 0 2.653-1.687 4.814-4.573 5.938z"></path>
                </svg>
              </div>
            </div>
            <div className="hosting-text">
              If you aren’t hosting on behalf of a registered business, update how you host.{" "}
              <Link to="/account-details/how-you-host">
                <button className="learn-btn">Update</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="progress-status">
        {kycLevels === 2 ? (
          <>
            <div className="status-item">
              <div className="status-section">
                <div className="status-icon">
                  <svg
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="presentation"
                    focusable="false"
                    style={{ display: "block", height: "24px", width: "24px", fill: "rgb(34 34 34)" }}
                  >
                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z"></path>
                  </svg>
                </div>
                <div className="item-bar next-bar"></div>
              </div>
              <div className="status-text">
                <div className="text-wrapper">
                  <div className="text-title">Your info</div>
                  <div className="sub-text">{yourInfo ? yourInfo.firstName + " " + yourInfo.lastName : null}</div>
                  <div className="edit-status">
                    <Link to={`${baseLink}/your-identity`}>
                      <button className="edit-btn">Edit</button>
                    </Link>
                  </div>
                  <hr className="divider" />
                </div>
              </div>
            </div>
            <div className="status-item">
              <div className="status-section">
                <div className="status-icon">
                  {userLevel > 1 ? (
                    <svg
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                      focusable="false"
                      style={{ display: "block", height: "24px", width: "24px", fill: "rgb(34 34 34)" }}
                    >
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z"></path>
                    </svg>
                  ) : (
                    <span className={userLevel === 1 ? "unfilled-status next-status" : "unfilled-status"}></span>
                  )}
                </div>
              </div>
              <div className="status-text">
                <div className="text-wrapper">
                  <div className="text-title">Account managers</div>
                  <div className="sub-text">
                    {hasAccountManager
                      ? hasAccountManager === "yes"
                        ? `View managers`
                        : "None"
                      : `Add anyone else who manages reservations or payments`}
                  </div>
                  <div className="edit-status">
                    {userLevel > 1 ? (
                      <Link
                        to={
                          hasAccountManager === "yes"
                            ? `${baseLink}/account-manager-review`
                            : `${baseLink}/has-account-manager`
                        }
                      >
                        <button className="edit-btn">Edit</button>
                      </Link>
                    ) : (
                      <div className="timer">
                        <div className="time-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            aria-hidden="true"
                            role="presentation"
                            focusable="false"
                            style={{ display: "block", height: "16px", width: "16px", fill: "currentcolor" }}
                          >
                            <path d="M8 .5a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15zm.81 2.078h-1.5V8.51l5.138 2.966.75-1.299-4.389-2.534V2.578z"></path>
                          </svg>
                        </div>
                        &nbsp; &nbsp; 2 min
                      </div>
                    )}
                  </div>
                  <hr className="divider" />
                </div>
              </div>
            </div>
          </>
        ) : kycLevels === 3 ? (
          <>
            <div className="status-item">
              <div className="status-section">
                <div className="status-icon">
                  <svg
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="presentation"
                    focusable="false"
                    style={{ display: "block", height: "24px", width: "24px", fill: "rgb(34 34 34)" }}
                  >
                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z"></path>
                  </svg>
                </div>
                <div className="item-bar next-bar"></div>
              </div>
              <div className="status-text">
                <div className="text-wrapper">
                  <div className="text-title">Your info</div>
                  <div className="sub-text">{yourInfo ? yourInfo.firstName + " " + yourInfo.lastName : null}</div>
                  <div className="edit-status">
                    <Link to={`${baseLink}/your-identity`}>
                      <button className="edit-btn">Edit</button>
                    </Link>
                  </div>
                  <hr className="divider" />
                </div>
              </div>
            </div>
            <div className="status-item">
              <div className="status-section">
                <div className="status-icon">
                  {userLevel > 1 ? (
                    <svg
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                      focusable="false"
                      style={{ display: "block", height: "24px", width: "24px", fill: "rgb(34 34 34)" }}
                    >
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z"></path>
                    </svg>
                  ) : (
                    <span className="unfilled-status next-status"></span>
                  )}
                </div>
                <div className={userLevel > 1 ? "item-bar next-bar" : "item-bar"}></div>
              </div>
              <div className="status-text">
                <div className="text-wrapper">
                  <div className="text-title">Business info</div>
                  <div className="sub-text">
                    {businessInfo?.legalBusinessName || "Add business name, phone number, and more"}
                  </div>
                  <div className="edit-status">
                    {userLevel > 1 ? (
                      <Link to={`${baseLink}/business-info`}>
                        <button className="edit-btn">Edit</button>
                      </Link>
                    ) : (
                      <div className="timer">
                        <div className="time-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            aria-hidden="true"
                            role="presentation"
                            focusable="false"
                            style={{ display: "block", height: "16px", width: "16px", fill: "currentcolor" }}
                          >
                            <path d="M8 .5a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15zm.81 2.078h-1.5V8.51l5.138 2.966.75-1.299-4.389-2.534V2.578z"></path>
                          </svg>
                        </div>
                        &nbsp; &nbsp; 5 min
                      </div>
                    )}
                  </div>
                  <hr className="divider" />
                </div>
              </div>
            </div>
            <div className="status-item">
              <div className="status-section">
                <div className="status-icon">
                  {userLevel > 2 ? (
                    <svg
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                      focusable="false"
                      style={{ display: "block", height: "24px", width: "24px", fill: "rgb(34 34 34)" }}
                    >
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z"></path>
                    </svg>
                  ) : (
                    <span className={userLevel === 2 ? "unfilled-status next-status" : "unfilled-status"}></span>
                  )}
                </div>
              </div>
              <div className="status-text">
                <div className="text-wrapper">
                  <div className="text-title">Account managers</div>
                  <div className="sub-text">
                    {hasAccountManager
                      ? hasAccountManager === "yes"
                        ? `View managers`
                        : "None"
                      : `Add anyone else who manages reservations or payments`}
                  </div>
                  <div className="edit-status">
                    {userLevel > 2 ? (
                      <Link
                        to={
                          hasAccountManager === "yes"
                            ? `${baseLink}/account-manager-review`
                            : `${baseLink}/has-account-manager`
                        }
                      >
                        <button className="edit-btn">Edit</button>
                      </Link>
                    ) : (
                      <div className="timer">
                        <div className="time-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            aria-hidden="true"
                            role="presentation"
                            focusable="false"
                            style={{ display: "block", height: "16px", width: "16px", fill: "currentcolor" }}
                          >
                            <path d="M8 .5a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15zm.81 2.078h-1.5V8.51l5.138 2.966.75-1.299-4.389-2.534V2.578z"></path>
                          </svg>
                        </div>
                        &nbsp; &nbsp; 2 min
                      </div>
                    )}
                  </div>
                  <hr className="divider" />
                </div>
              </div>
            </div>
          </>
        ) : kycLevels === 4 ? (
          <>
            <div className="status-item">
              <div className="status-section">
                <div className="status-icon">
                  <svg
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                    role="presentation"
                    focusable="false"
                    style={{ display: "block", height: "24px", width: "24px", fill: "rgb(34 34 34)" }}
                  >
                    <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z"></path>
                  </svg>
                </div>
                <div className="item-bar next-bar"></div>
              </div>
              <div className="status-text">
                <div className="text-wrapper">
                  <div className="text-title">Your info</div>
                  <div className="sub-text">{yourInfo ? yourInfo.firstName + " " + yourInfo.lastName : null}</div>
                  <div className="edit-status">
                    <Link to={`${baseLink}/your-identity`}>
                      <button className="edit-btn">Edit</button>
                    </Link>
                  </div>
                  <hr className="divider" />
                </div>
              </div>
            </div>
            <div className="status-item">
              <div className="status-section">
                <div className="status-icon">
                  {userLevel > 1 ? (
                    <svg
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                      focusable="false"
                      style={{ display: "block", height: "24px", width: "24px", fill: "rgb(34 34 34)" }}
                    >
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z"></path>
                    </svg>
                  ) : (
                    <span className="unfilled-status next-status"></span>
                  )}
                </div>
                <div className={userLevel > 1 ? "item-bar next-bar" : "item-bar"}></div>
              </div>
              <div className="status-text">
                <div className="text-wrapper">
                  <div className="text-title">Business info</div>
                  <div className="sub-text">
                    {businessInfo?.legalBusinessName || "Add business name, phone number, and more"}
                  </div>
                  <div className="edit-status">
                    {userLevel > 1 ? (
                      <Link to={`${baseLink}/business-info`}>
                        <button className="edit-btn">Edit</button>
                      </Link>
                    ) : (
                      <div className="timer">
                        <div className="time-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            aria-hidden="true"
                            role="presentation"
                            focusable="false"
                            style={{ display: "block", height: "16px", width: "16px", fill: "currentcolor" }}
                          >
                            <path d="M8 .5a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15zm.81 2.078h-1.5V8.51l5.138 2.966.75-1.299-4.389-2.534V2.578z"></path>
                          </svg>
                        </div>
                        &nbsp; &nbsp; 5 min
                      </div>
                    )}
                  </div>
                  <hr className="divider" />
                </div>
              </div>
            </div>
            <div className="status-item">
              <div className="status-section">
                <div className="status-icon">
                  {userLevel > 2 ? (
                    <svg
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                      focusable="false"
                      style={{ display: "block", height: "24px", width: "24px", fill: "rgb(34 34 34)" }}
                    >
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z"></path>
                    </svg>
                  ) : (
                    <span className={userLevel === 2 ? "unfilled-status next-status" : "unfilled-status"}></span>
                  )}
                </div>
                <div className={userLevel > 2 ? "item-bar next-bar" : "item-bar"}></div>
              </div>
              <div className="status-text">
                <div className="text-wrapper">
                  <div className="text-title">Beneficial owners</div>
                  <div className="sub-text">
                    {beneficialOwners?.isBeneficialOwner === "yes"
                      ? `${yourInfo?.firstName} ${yourInfo?.lastName}: ${beneficialOwners?.percentOwned}%`
                      : "Add anyone else who owns or controls the business"}
                  </div>
                  <div className="edit-status">
                    {userLevel > 2 ? (
                      <Link
                        to={
                          beneficialOwners?.isBeneficialOwner === "yes" || otherBeneficialOwners?.length > 0
                            ? `${baseLink}/beneficial-owner-review`
                            : `${baseLink}/is-beneficial-owner`
                        }
                      >
                        <button className="edit-btn">Edit</button>
                      </Link>
                    ) : (
                      <div className="timer">
                        <div className="time-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            aria-hidden="true"
                            role="presentation"
                            focusable="false"
                            style={{ display: "block", height: "16px", width: "16px", fill: "currentcolor" }}
                          >
                            <path d="M8 .5a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15zm.81 2.078h-1.5V8.51l5.138 2.966.75-1.299-4.389-2.534V2.578z"></path>
                          </svg>
                        </div>
                        &nbsp; &nbsp; 5 min
                      </div>
                    )}
                  </div>
                  <hr className="divider" />
                </div>
              </div>
            </div>
            <div className="status-item">
              <div className="status-section">
                <div className="status-icon">
                  {userLevel > 3 ? (
                    <svg
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                      focusable="false"
                      style={{ display: "block", height: "24px", width: "24px", fill: "rgb(34 34 34)" }}
                    >
                      <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zm3.159 4.869L6.67 9.355 4.42 7.105 3.289 8.236 6.67 11.62 12.291 6z"></path>
                    </svg>
                  ) : (
                    <span className={userLevel === 3 ? "unfilled-status next-status" : "unfilled-status"}></span>
                  )}
                </div>
              </div>
              <div className="status-text">
                <div className="text-wrapper">
                  <div className="text-title">Account managers</div>
                  <div className="sub-text">
                    {hasAccountManager
                      ? hasAccountManager === "yes"
                        ? `View managers`
                        : "None"
                      : `Add anyone else who manages reservations or payments`}
                  </div>
                  <div className="edit-status">
                    {userLevel > 3 ? (
                      <Link
                        to={
                          hasAccountManager === "yes"
                            ? `${baseLink}/account-manager-review`
                            : `${baseLink}/has-account-manager`
                        }
                      >
                        <button className="edit-btn">Edit</button>
                      </Link>
                    ) : (
                      <div className="timer">
                        <div className="time-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 16 16"
                            aria-hidden="true"
                            role="presentation"
                            focusable="false"
                            style={{ display: "block", height: "16px", width: "16px", fill: "currentcolor" }}
                          >
                            <path d="M8 .5a7.5 7.5 0 1 1 0 15 7.5 7.5 0 0 1 0-15zm.81 2.078h-1.5V8.51l5.138 2.966.75-1.299-4.389-2.534V2.578z"></path>
                          </svg>
                        </div>
                        &nbsp; &nbsp; 2 min
                      </div>
                    )}
                  </div>
                  <hr className="divider" />
                </div>
              </div>
            </div>
          </>
        ) : null}
        <div className="action-buttons">
          <Link className="continue" to={`${baseLink}${path}`}>
            <button className="btn-continue">Continue</button>
          </Link>
          &nbsp; &nbsp;
          <Link to="/">
            <button className="save-exit-btn">Save and exit</button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default StatusContent
